@font-face {
  font-family: 'FrutigerLTW02';
  src: url('../fonts/FrutigerLTW02-55Roman.woff2') format('woff2'),
    url('../fonts/FrutigerLTW02-55Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FrutigerLTW02';
  src: url('../fonts/FrutigerLTW02-65Bold.woff2') format('woff2'),
    url('../fonts/FrutigerLTW02-65Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FrutigerLTW02';
  src: url('../fonts/FrutigerLTW02-75Black.woff2') format('woff2'),
    url('../fonts/FrutigerLTW02-75Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
