@import '../../../../assets/scss/utils/index.scss';

.accordeon-item {
  color: $white;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}

.is-open {
  .text {
    display: block;
    height: 92px;
    opacity: 1;
    transition: opacity 500ms ease-in-out, height 500ms ease-in-out;
  }
  .icon {
    transform: rotate(180deg);
  }
}

.title {
  @include type-style('body-small');
  @include padding-top('sm');
  @include padding-bottom('sm');
  display: flex;
  border-top: 2px solid $white;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  transition: transform 500ms;
}

.text {
  display: block;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 500ms ease-in-out, height 500ms ease-in-out;
}

.text p {
  @include type-style('body-xsmall');
  @include margin-top('3xs');
  @include margin-bottom('sm');
}
