@import '../../../assets/scss/utils/index.scss';

$image-offset: 112px;

.book-teaser {
  @include size(326px, auto);
  margin-top: $image-offset;
}

.inner {
  padding-top: $image-offset;
  background: $babyblau;
  height: 398px;
}

.image-container {
  @include size(255px, 280px);
  position: relative;
  margin: calc(-#{$image-offset}* 2) auto 0 auto;

  img {
    height: auto;
    max-height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    max-width: 100%;
    box-shadow: 15px 15px 30px 0px rgba(0, 0, 0, 0.5);
  }
}

.title {
  @include type-style('h4');
  @include margin-top('none');
  @include margin-bottom('sm');
  color: $zueriblau;
}

.content-container {
  @include padding('lg');
}
