@import '../../../assets/scss/utils/index.scss';

.filter-button {
  @include type-style('button-medium');
  @include padding-top('xs');
  @include padding-right('md');
  @include padding-bottom('xs');
  @include padding-left('md');
  display: inline-block;
  position: relative;
  width: auto;
  border: none;
  transition: all 0.15s ease-in;
  background: $babyblau;
  user-select: none;
  cursor: pointer;
}

.label {
  color: $zueriblau;
}

.is-disabled {
  background: $grau-hell;
}

.is-disabled {
  .label {
    color: $grau-dunkel;
  }
}

.is-selected {
  background: $limmatblau;
}

.is-selected {
  .label {
    color: $white;
  }
}
