@import '../../../assets/scss/utils/index.scss';

.inspiration-overlay-event {
  background-color: $nachtblau;
  color: $white;
  position: relative;
  width: 100%;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.event-container {
  @include padding('2xl');
  @include padding-top('xl');
  @include gap('3xl');
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.info-wrapper {
  @include type-style('body-xlarge');
  @include gap('sm');
  margin-left: $speech-bubble-arrow-size;
  display: flex;
  flex-direction: column;
}

.info-entry {
  display: flex;
}

.icon-container {
  @include margin-right('sm');
}

.quote {
  @include padding-left('2xl');
  @include padding-right('2xl');
  @include padding-bottom('2xl');
  position: absolute;
  inset: auto 0 0 0;
  width: 100%;
}

.close-icon {
  @include margin-top('2xl');
  @include margin-right('2xl');
  position: absolute;
  top: 0;
  right: 0;
}
