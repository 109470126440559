@keyframes showAnim {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(1.025);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hideAnim {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.025);
    opacity: 1;
  }
  100% {
    transform: scale(0.85);
    opacity: 0;
  }
}
