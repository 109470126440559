@import '../../../assets/scss/utils/index.scss';

.generosity-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble {
  @include square(505px);
  @include padding('2xl');
  color: $white;
  background: $limmatblau;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > * {
    opacity: 0;
    transform: translateY(10px);
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        $delay: (125ms * ($i - 1));
        transition: opacity 250ms 250ms + $delay, transform 250ms 250ms + $delay;
      }
    }
  }
}

.title {
  @include type-style('h2');
  @include margin-top('lg');
}

.description {
  @include type-style('body-medium');
  @include margin-top('sm');
}

:global {
  .generosity-overlay--enter,
  .generosity-overlay--appear {
    > * {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  .generosity-overlay--enter-done,
  .generosity-overlay--enter-active,
  .generosity-overlay--appear-active {
    > * {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
