@import '../../../assets/scss/utils/index.scss';

.pictogram {
  @include square($pictogram-size--medium);
}

.size-medium {
  @include square($pictogram-size--medium);
}

.size-large {
  @include square($pictogram-size--large);
}
