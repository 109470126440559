@import '../../../assets/scss/utils/index.scss';

.inspiration-overlay-base {
  @include type-style('body-large');
  @include padding-top('2xl');
  @include padding-right('2xl');
  @include padding-bottom('2xl');
  @include padding-left('2xl');
  display: flex;
  align-items: center;
  background-color: $babyblau;
  color: $zueriblau;
}

.wrapper {
  // ...
}

.kicker {
  @include type-style('body-xsmall');
  @include margin-bottom('xs');
}

.title {
  @include type-style('h2');
  @include margin-bottom('md');
  @include padding-right('xl');
  hyphens: auto;
}

.text {
  @include type-style('body-xsmall');
}

.subline {
  @include type-style('body-xsmall');
}

.price {
  @include type-style('h2');
  @include margin-top('2xl');
}

.qrcodeWrapper {
  @include margin-top('xl');
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode {
  @include margin-top('none');
  width: 88px;
}

.qrCodeLabel {
  @include margin-top('sm');
  @include margin-bottom('none');
  @include type-style('button-medium');
}
