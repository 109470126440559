// Colors
// -------------------------
$zueriblau: #003cb4;
$nachtblau: #000078;
$babyblau: #eff5ff;
$black: #000000;
$white: #ffffff;
$lichtblau-hell: #87dcff;
$limmatblau: #006cff;
$seeblau-hell: #82e1e1;
$grau-dunkel: #a8a8a8;
$grau-hell: #f3f3f3;

// Typography
// -------------------------
$sans-font-family: 'FrutigerLTW02', Helvetica, Arial, sans-serif;
$default-font: 'sans';
$default-type-style: 'default';
$default-font-weight: 'regular';

// Ratio container
// -------------------------
$visual-ratio-1_1: 100%;
$visual-ratio-4_3: 75%;
$visual-ratio-16_9: 56%;

// Buttons
// ------------------------
$button-padding-horizontal: 26px;
$button-padding-vertical: 12px;

// Icons
// ------------------------
$icon-size--small: 32px;
$icon-size--medium: 40px;
$icon-size--large: 50px;

// Pictos
// ------------------------
$pictogram-size--medium: 36px;
$pictogram-size--large: 75px;

// Speech-bubble /
$speech-bubble-arrow-size: 44px;

// Responsive breakpoints
// -------------------------
// cleanup todo
// in order to use the gold mixins the breakpoint variable is needed, it!
$breakpoints: ();

$hover-selector: 'html:not([data-whatintent="touch"])';
