@import '../../../assets/scss/utils/index.scss';

.event-teaser {
  @include padding('lg');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 326px;
  height: 246px;
  position: relative;
  color: $zueriblau;
  border: 2px solid $zueriblau;
  background: $white;
}

.top {
  display: flex;
  flex-direction: column;
}

.header {
  @include type-style('h4');
  @include margin-bottom('sm');
  display: flex;
  justify-content: space-between;
}

.text-container {
  @include type-style('body-xsmall');
}

.icon-container {
  // ...
}
