@import '../../../assets/scss/utils/index.scss';

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $white;
  animation: spin 1.5s linear infinite;
  z-index: 1000;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $zueriblau;
    animation: spin 1.5s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
