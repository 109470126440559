@import '../../../assets/scss/utils/index.scss';

.footer-section {
  @include type-style('body-large');
  @include padding-top('3xl');
  @include padding-right('3xl');
  @include padding-bottom('3xl');
  @include padding-left('3xl');
  @include margin-left('2xl');
  @include margin-right('2xl');
  background-color: $nachtblau;
  color: $white;
  display: flex;
  align-items: center;

  :global svg {
    path {
      shape-rendering: crispEdges;
    }
  }
}

.qrCode {
  @include margin-left('3xl');
}

.ctaLabel {
  text-align: center;
}
