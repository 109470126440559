@import '../../../assets/scss/utils/index.scss';

.view {
  display: flex;
  position: absolute;
  inset: 0;
  width: 100vw;
  will-change: opacity;
  overflow-x: hidden;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $babyblau;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($limmatblau, 1);
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba($limmatblau, 1);
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: rgba($limmatblau, 1) transparent;
  }

  &--vertically {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--horizontally {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

:global {
  .main-pagination-enter {
    opacity: 0;
  }
  .main-pagination-enter.main-pagination-enter-active {
    opacity: 1;
    transition: opacity 500ms 500ms ease;
    pointer-events: none;
    z-index: 1;
  }
  .main-pagination-exit {
    opacity: 1;
  }
  .main-pagination-exit.main-pagination-exit-active {
    opacity: 0;
    transition: opacity 500ms 0ms ease;
    pointer-events: none;
  }
}
