@import '../../../assets/scss/utils/index.scss';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: $white;
  background-color: rgba(0, 0, 120, 0.8);
  overflow: hidden;
  will-change: opacity;
  transition: opacity 500ms 0ms ease;
}

.wrapper {
  display: flex;
  width: 75%;
  min-height: 75%;
  will-change: opacity, transform;
  transition: opacity 500ms 0ms ease, transform 500ms 0ms ease;
  & > div {
    flex: 1;
  }
}

:global {
  .modal--enter {
    :local .wrapper {
      transform: scale(0.95);
    }
    opacity: 0;
  }
  .modal--enter.modal--enter-active {
    :local .wrapper {
      transform: scale(1);
    }
    opacity: 1;
    pointer-events: none;
    z-index: 1;
  }
  .modal--exit {
    :local .wrapper {
      transform: scale(1);
    }
    opacity: 1;
  }
  .modal--exit.modal--exit-active {
    :local .wrapper {
      transform: scale(0.95);
    }
    opacity: 0;
    pointer-events: none;
  }
}
