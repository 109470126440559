@import '../../../assets/scss/utils/index.scss';

.icon {
  @include square($icon-size--medium);
}

.size-small {
  @include square($icon-size--small);
}

.size-medium {
  @include square($icon-size--medium);
}

.size-large {
  @include square($icon-size--large);
}
