@import '../../../assets/scss/utils/index.scss';

.onsite-group {
  @include margin-left('2xl');
  @include margin-right('2xl');
  position: relative;
  display: inline-block;

  opacity: 0;
  transform: translateY(10%);

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      $delay: (250ms * ($i - 1));
      transition: opacity 500ms 0ms + $delay ease,
        transform 500ms 0ms + $delay ease;
    }
  }
}
