@import '../../../assets/scss/utils/index.scss';

$border-width: 2px;

.quote-bubble {
  position: relative;
  max-width: 450px;
  padding-left: $speech-bubble-arrow-size;
}

.text-container {
  @include padding('md');
  @include type-style('body-xsmall');
  color: $zueriblau;
  border: $border-width solid $zueriblau;
}

.triangle-container {
  position: absolute;
  bottom: 0;
  left: $border-width;
  width: auto;
  height: auto;
  background-color: $white;

  img {
    display: block;
    width: 43px;
    height: 41px;
  }
}

.inverted {

  .text-container {
    color: $white;
    border-color: $white;
  }

  .triangle-container {
    background-color: $nachtblau;

    img {
      filter: brightness(0) invert(1);
    }
  }
}
