@import '../../../assets/scss/utils/index.scss';

.generosity-teaser {
  @include square(170px);
  @include padding('md');
  @include type-style('button-medium');
  color: $white;
  background: $limmatblau;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
