@import '../../../assets/scss/utils/index.scss';

.loading-screen {
  @include gap('lg');
  display: flex;
  position: fixed;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $nachtblau;
  transition: opacity 700ms 780ms, transform 780ms ease-in-out;
  overflow: hidden;

  &.enter-active,
  &.enter-done,
  &.exit {
    opacity: 1;
  }

  &.exit-active,
  &.exit-done,
  &.enter {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.spinner {
  display: flex;
  justify-content: center;
  position: relative;
}

.text {
  @include type-style('h2');
  position: relative;
  color: $white;
}

.lottie-anim--stars {
  position: absolute;
  inset: 0;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      $nachtblau 50%,
      rgb(0, 0, 120, 0.5) 100%
    );
  }
}
