@import '../../../assets/scss/utils/index.scss';

.stepper {
  @include size(4px, 289px);
  position: relative;
  background: $white;
  overflow: hidden;
}

.progress {
  position: absolute;
  inset: 0;
  background: $limmatblau;
  transform-origin: top;
  transition: transform 0.2s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
}
