@import '../../../assets/scss/utils/index.scss';

$component-height: 246px;

.boutique-article-teaser {
  margin-left: $component-height * 0.5;
}

.inner {
  @include padding-right('lg');
  width: 458px;
  display: inline-flex;
  background: $babyblau;
  padding-left: $component-height * 0.5;
}

.image-container {
  @include square($component-height);
  @include margin-right('2xl');
  margin-left: calc(-#{$component-height});
  position: relative;
  flex-shrink: 0;

  img {
    @include square(100%);
    position: absolute;
    object-fit: contain;
    object-position: center center;
  }
}

.icon-container {
  @include margin-bottom('lg');
}

.title {
  @include type-style('h4');
  @include margin-top('lg');
  @include margin-bottom('sm');
  color: $zueriblau;
}
