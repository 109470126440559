// Sizing shortcuts
// -------------------------
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

// organize the stacking order using the $layers variable
@mixin z-index($level: 'content') {
  z-index: map-get($layers, $level);
}

/**
 * Mixin to print any kind of font contained in the $type-styles map
 * @param   { String } $id:        'type-style-1'
 * @param   { Boolean } $use-media: true
 */
@mixin type-style(
  $id: $default-type-style,
  $use-media: true,
  $use-font-config: true
) {
  @if map-has-key($type-styles, $id) == false {
    @error 'No type-style `#{$id}` found. ' + 'Please make sure it is defined in `$type-styles` map.';
  }

  $fontType: map-get($type-styles, $id);
  // loop all the properties found in this font type
  @each $prop, $val in $fontType {
    // check if this font has special media values
    // normally they should be a map as well
    @if ($prop == 'media' and $use-media) {
      // loop all the media types
      @each $media-type, $media-values in $val {
        // create the responsive rules
        @include respond-to($media-type) {
          // print all the media rules
          @each $prop, $val in $media-values {
            #{$prop}: #{$val};
          }
        }
      }
    } @else if ($prop == 'config' and $use-font-config) {
      $font: map_get($val, 'font');
      $font-weight: map_get($val, 'weight');
      @include font-family($font);
      @include font-weight($font-weight, $font);
    } @else {
      @if ($prop != 'media') {
        #{$prop}: #{$val};
      }
    }
  }
}

// Return font-weight
@mixin font-weight($weight: $default-font-weight, $font: $default-font) {
  $font-config: map_get($fonts, $font);
  $font-weights: map_get($font-config, 'weights');
  $font-weight: map_get($font-weights, $weight);
  font-weight: $font-weight;
}

// Return font-family
@mixin font-family($font: $default-font) {
  $font-config: map_get($fonts, $font);
  $font-family: map_get($font-config, 'family');
  font-family: $font-family;
}

/// https://css-tricks.com/snippets/sass/viewport-sized-typography-minimum-maximum-sizes/
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

@mixin spacing($property, $size, $position, $negative: false) {
  @if map-has-key($spacings, $size) == false {
    @error 'No spacing `#{$size}` found. ' + 'Please make sure it is defined in `$spacings` map.';
  }

  $propertyName: $property !default;

  @if $position {
    $propertyName: $property + '-' + $position;
  }

  $spacingMap: map-get($spacings, $size);

  @if $negative {
    #{$propertyName}: -#{map-get($spacingMap, 'default')};
  } @else {
    #{$propertyName}: map-get($spacingMap, 'default');
  }

  @each $breakpoint, $size in $spacingMap {
    @if map-has-key($breakpoints, $breakpoint) {
      @include respond-to($breakpoint) {
        @if $negative {
          #{$propertyName}: -#{$size};
        } @else {
          #{$propertyName}: $size;
        }
      }
    }
  }
}

@mixin margin($size, $margin-position: null, $negative: false) {
  @include spacing('margin', $size, $margin-position, $negative);
}

@mixin margin-top($size, $negative: false) {
  @include margin($size, 'top', $negative);
}

@mixin margin-right($size, $negative: false) {
  @include margin($size, 'right', $negative);
}

@mixin margin-bottom($size, $negative: false) {
  @include margin($size, 'bottom', $negative);
}

@mixin margin-left($size, $negative: false) {
  @include margin($size, 'left', $negative);
}

@mixin padding($size, $padding-position: null) {
  @include spacing('padding', $size, $padding-position);
}

@mixin padding-top($size) {
  @include padding($size, 'top');
}

@mixin padding-right($size) {
  @include padding($size, 'right');
}

@mixin padding-bottom($size) {
  @include padding($size, 'bottom');
}

@mixin padding-left($size) {
  @include padding($size, 'left');
}

@mixin gap($size, $gap-position: null, $negative: false) {
  @include spacing('gap', $size, $gap-position, $negative);
}

$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$positions: (('t', 'top'), ('r', 'right'), ('b', 'bottom'), ('l', 'left'));

@each $spacingKey, $spacingMap in $spacings {
  .#{$marginKey}#{$separator}#{$spacingKey} {
    @include margin($spacingKey);
  }
  .#{$paddingKey}#{$separator}#{$spacingKey} {
    @include padding($spacingKey);
  }
  @each $position in $positions {
    $posKey: nth($position, 1);
    $posValue: nth($position, 2);
    .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$spacingKey} {
      @include margin($spacingKey, $posValue);
    }
    .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$spacingKey} {
      @include padding($spacingKey, $posValue);
    }
  }
}

@each $font-name, $font-map in $type-styles {
  .type-style--#{$font-name} {
    @include type-style($font-name);
  }
}

@mixin font-face(
  $name,
  $path,
  $types: eot woff2 woff ttf,
  $weight: null,
  $style: null,
  $display: $default-font-display
) {
  $ie-src: null;
  $src: null;

  $type-suffix: (
    eot: '?#iefix',
  );

  $formats: (
    eot: 'embedded-opentype',
    ttf: 'truetype',
    otf: 'opentype',
  );

  /* IE9 Compat Modes */
  @if (nth($types, 1) == eot) {
    $ie-src: url(quote($path + '.eot'));
  }

  /* IE6-IE8, Modern Browsers, Safari, Andriod, iOS */
  @each $type in $types {
    $file: if(
      map-has-key($type-suffix, $type),
      $type + map-get($type-suffix, $type),
      $type
    );
    $format: if(map-has-key($formats, $type), map-get($formats, $type), $type);
    $src: append(
      $src,
      url(quote($path + '.' + $file)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    src: $ie-src;
    src: $src;
    font-weight: $weight;
    font-style: $style;
    font-display: $display;
  }
}

@mixin underline($thickness: 1px, $offset: 4px, $color: inherit) {
  text-decoration: underline;
  text-decoration-thickness: $thickness;
  text-underline-offset: $offset;
  text-decoration-color: $color;
}

@mixin translate($sizeX, $sizeY, $negativeX: false, $negativeY: false) {
  @if map-has-key($spacings, $sizeX) == false {
    @error 'No spacing for X-axis: `#{$sizeX}` found. ' + 'Please make sure it is defined in `$spacings` map.';
  }

  @if map-has-key($spacings, $sizeY) == false {
    @error 'No spacing for Y-axis: `#{$sizeY}` found. ' + 'Please make sure it is defined in `$spacings` map.';
  }

  $spacingMapX: map-get($spacings, $sizeX);
  $spacingMapY: map-get($spacings, $sizeY);

  @if $negativeX {
    $negativeX: '-';
  } @else {
    $negativeX: null;
  }

  @if $negativeY {
    $negativeY: '-';
  } @else {
    $negativeY: null;
  }

  transform: translate(
    #{$negativeX}#{map-get($spacingMapX, 'default')},
    #{$negativeY}#{map-get($spacingMapY, 'default')}
  );

  // Create Map with values for X
  $xMap: ();
  @each $breakpoint, $sizeX in $spacingMapX {
    @if map-has-key($breakpoints, $breakpoint) {
      $xMap: map-merge(
        $xMap,
        (
          $breakpoint: $sizeX,
        )
      );
    }
  }

  // Merge Map with values of Y
  $xyMap: ();
  @each $breakpoint, $sizeY in $spacingMapY {
    @if map-has-key($breakpoints, $breakpoint) {
      @if map-has-key($xMap, $breakpoint) {
        $sizeX: map-get($xMap, $breakpoint);
        $xyMap: map-merge(
          $xyMap,
          (
            $breakpoint: (
              #{$negativeX}#{$sizeX},
              #{$negativeY}#{$sizeY},
            ),
          )
        );
      }
    }
  }

  // Create media queries
  @each $breakpoint, $size in $xyMap {
    @if map-has-key($breakpoints, $breakpoint) {
      @include respond-to($breakpoint) {
        transform: translate(#{$size});
      }
    }
  }
}

@mixin translateAxis($size, $axis, $negative: false) {
  @if map-has-key($spacings, $size) == false {
    @error 'No spacing `#{$size}` found. ' + 'Please make sure it is defined in `$spacings` map.';
  }

  $spacingMap: map-get($spacings, $size);

  @if $negative {
    transform: unquote("#{$axis}(-#{map-get($spacingMap, 'default')})");
  } @else {
    transform: unquote("#{$axis}(#{map-get($spacingMap, 'default')})");
  }

  @each $breakpoint, $size in $spacingMap {
    @if map-has-key($breakpoints, $breakpoint) {
      @include respond-to($breakpoint) {
        @if $negative {
          transform: unquote('#{$axis}(-#{$size})');
        } @else {
          transform: unquote('#{$axis}(#{$size})');
        }
      }
    }
  }
}

@mixin translateX($size, $negative: false) {
  @include translateAxis($size, 'translateX', $negative);
}

@mixin translateY($size, $negative: false) {
  @include translateAxis($size, 'translateY', $negative);
}

// Um den "hovered" state in Storybook abzubilden wird ein html attribut gesetzt, welches man mittel "&[hovered]" ansteuern kann
@mixin hover() {
  #{$hover-selector} &:hover,
  &[hovered] {
    &:not([disabled]) {
      @content;
    }
  }
}
