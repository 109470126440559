@import '../../../assets/scss/utils/index.scss';

.inspiration-overlay-product {
  @include type-style('body-large');
  background-color: $white;
  color: $nachtblau;
  position: relative;
  width: 100%;
}

.close-icon {
  @include margin-top('2xl');
  @include margin-right('2xl');
  position: absolute;
  top: 0;
  right: 0;
}

.image-container {
  position: relative;
  flex-grow: 1;
  width: 100%;

  > img {
    @include square(100%);
    position: absolute;
    object-fit: contain;
  }
}

.product-container {
  @include padding('2xl');
  @include padding-top('xl');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.quote {
  @include margin-top('2xl');
  width: 100%;
}
