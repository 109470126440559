@import '../../../assets/scss/utils/index.scss';

.answer-multiselect {
  @include gap('md');
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 1000px;

  > * {
    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        $delay: (75ms * ($i - 1));
        opacity: 0;
        transform: scale(0);
        :global {
          animation: showAnim 500ms 1250ms + $delay forwards;
        }
      }
    }
  }
}
