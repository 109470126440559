@import '../../../assets/scss/utils/index.scss';

.masonry {
  @include padding-top('2xl');
  @include padding-bottom('xl');
  flex: 1;
}

.horizontal {
  > * {
    @include margin-right('xl');
  }
}
