@import '../../../../assets/scss/utils/index.scss';

.chat-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.chat-bubble {
  @include margin-top('4xl');
  @include margin-left('3xl');
  position: absolute;
  top: 0;
  left: 0;
}

.button-group {
  @include margin-bottom('4xl');
  @include margin-right('4xl');
  @include padding-right('2xl');
  @include gap('xl');
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;

  button {
    @for $i from 1 through 2 {
      &:nth-of-type(#{$i}) {
        $delay: (125ms * ($i - 1));
        opacity: 0;
        transform: scale(0);
        :global {
          animation: showAnim 500ms 1750ms + $delay forwards;
        }
      }
    }
  }
}

.flex-column {
  @include gap('lg');
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

:global {
  .chat-pagination-enter,
  .chat-pagination-appear {
    :local .chat-bubble {
      opacity: 0;
      transform: translateY(360px);
    }
    :local .button-group {
      transform: translateY(360px);
    }
  }
  .chat-pagination-enter.chat-pagination-enter-active,
  .chat-pagination-appear.chat-pagination-appear-active {
    :local .chat-bubble {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 500ms 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55),
        transform 500ms 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
      pointer-events: none;
      z-index: 1;
    }
    :local .button-group {
      transform: translateY(0);
      transition: transform 500ms 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
      pointer-events: none;
      z-index: 1;
    }
  }
  .chat-pagination-exit {
    :local .chat-bubble,
    :local .button-group {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .chat-pagination-exit.chat-pagination-exit-active {
    :local .chat-bubble,
    :local .button-group {
      opacity: 0;
      transform: translateY(-180px);
      transition: opacity 200ms 0ms ease, transform 250ms 0ms ease;
      pointer-events: none;
    }
    :local .button-group {
      transform: translateY(-90px);
      transition: opacity 200ms 75ms ease, transform 250ms 75ms ease;
    }
  }
}
