@import '../../../assets/scss/utils/index.scss';

.chat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.stepper {
  position: absolute;
  right: 76px;
}

.lottie-anim--stars {
  position: absolute;
  inset: 0;
  svg {
    object-fit: fill;
  }
}
