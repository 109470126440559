@import '../../../assets/scss/utils/index.scss';

.close-button {
  @include margin-top('lg');
  @include margin-right('lg');
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 22px;
  background-color: transparent;
  border: 0 none;
  z-index: 999;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.show-delayed {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 250ms, transform 250ms;
}

.show {
  opacity: 1;
  transform: scale(1);
}

:global .inspiration {
  @include margin-top('lg');
  @include margin-right('lg');
}

:global .generosity {
  margin: 60px 256px 0 0;
}

:global .restart {
  margin: 158px 170px 0 0;
}
