@import '../../../assets/scss/utils/index.scss';

.team-section {
  @include type-style('body-large');
  @include padding-top('3xl');
  @include padding-right('2xl');
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $nachtblau;
}

.header {
  @include padding-left('3xl');
  @include margin-left('4xl');
}

.title {
  @include type-style('h2');
  @include margin-bottom('2xl');
  color: $zueriblau;
}

.grid {
  @include gap('md');
  display: grid;
  align-items: center;
  grid-template-columns: 1.1fr 1fr;
}

figure {
  img {
    @include size(100%, auto);
  }
}
