@import '../../../../assets/scss/utils/index.scss';

.intro-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.lottie-anim {
  display: block;
  width: 80%;
  height: 80%;
  svg {
    object-fit: contain;
  }
}

.lottie-anim--stars {
  position: absolute;
  inset: 0;
  svg {
    object-fit: fill;
  }
}

.text {
  @include type-style('h2-intro');
  position: absolute;
  top: 0;
  left: 0;
  color: $white;
  span {
    display: block;
  }
  &--appear,
  &--enter {
    span {
      opacity: 0;
      transform: translateY(56px);
    }
  }
  &--appear-active,
  &--appear-done,
  &--enter-active,
  &--enter-done {
    span {
      opacity: 1;
      transform: translateY(0);
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          $delay: (250ms * ($i - 1));
          transition: opacity
              500ms
              1000ms +
              $delay
              cubic-bezier(0.08, 0.82, 0.17, 1),
            transform 500ms 1000ms + $delay cubic-bezier(0.08, 0.82, 0.17, 1);
        }
      }
    }
  }
  &--exit {
    span {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &--exit-active,
  &--exit-done {
    span {
      opacity: 0;
      transition: opacity 1000ms 1000ms ease;
    }
  }
  &--exit-active-first,
  &--exit-done-first {
    span {
      opacity: 0;
      transform: translateY(-56px);
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          $delay: (250ms * ($i - 1));
          transition: opacity 1000ms 0ms + $delay ease,
            transform 1000ms 0ms + $delay ease;
        }
      }
    }
  }
}

.page-title {
  .text {
    @include type-style('h1-splash');
    @include margin-top('lg');
    @include margin-left('xl');
  }
}

.page-even {
  .bg {
    background: linear-gradient(
      0deg,
      rgba($zueriblau, 1) 0%,
      rgba($zueriblau, 1) 40%,
      rgba($nachtblau, 1) 40%,
      rgba($nachtblau, 1) 100%
    );
  }
  .lottie-anim {
    margin-right: -30%;
    margin-bottom: 6%;
  }
  .text {
    @include margin-left('xl');
    top: auto;
    bottom: 25%;
  }
}

.page-odd {
  .bg {
    background: linear-gradient(
      0deg,
      rgba($nachtblau, 1) 0%,
      rgba($nachtblau, 1) 40%,
      rgba($zueriblau, 1) 40%,
      rgba($zueriblau, 1) 100%
    );
  }
  .lottie-anim {
    margin-left: -30%;
    margin-bottom: -12%;
  }
  .text {
    @include margin-right('xl');
    top: 320px;
    left: auto;
    right: 0;
  }
}

.bg {
  position: absolute;
  inset: 0;
}
