@import '../../../assets/scss/utils/index.scss';

.profile-section {
  @include padding-top('3xl');
  @include padding-right('4xl');
  @include padding-bottom('3xl');
  @include padding-left('4xl');
  @include gap('md');
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  background-color: $nachtblau;
  color: $white;
  white-space: break-spaces;

  > div:first-child {
    min-height: 0;
    height: 100%;
    svg {
      height: auto;
    }
  }
}

.lottie-anim {
  width: 100%;
  height: 585px;
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 250ms 250ms cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 250ms 250ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.right {
  width: 100%;
}

.title {
  @include type-style('h1');
  @include margin-top('lg');
  @include margin-bottom('lg');
  display: block;
  color: $white;
  opacity: 0;
  transform: translateY(40%);
  transition: opacity 250ms 500ms cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 250ms 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.accordion-wrapper {
  overflow-x: hidden;
  width: 50%;
  opacity: 0;
  transition: opacity 250ms 1000ms cubic-bezier(0.34, 1.56, 0.64, 1),
    width 250ms 1000ms ease-in-out;
}

.description {
  @include type-style('body-large');
  @include margin-bottom('2xl');
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 250ms 750ms cubic-bezier(0.34, 1.56, 0.64, 1),
    transform 250ms 750ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.ready {
  .title,
  .description,
  .lottie-anim {
    opacity: 1;
    transform: translateY(0);
  }
  .accordion-wrapper {
    opacity: 1;
    width: 100%;
  }
}
