@import '../../../assets/scss/utils/index.scss';

.inspiration-overlay-book {
  @include type-style('body-large');
  background-color: $white;
  color: $nachtblau;
  position: relative;
  width: 100%;
}

.close-icon {
  @include margin-top('2xl');
  @include margin-right('2xl');
  position: absolute;
  top: 0;
  right: 0;
}

.image-container {
  @include size(370px, 425px);
  position: relative;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 15px 15px 30px 0px rgba(0, 0, 0, 0.5);
    max-height: 100%;
    max-width: 100%;
  }
}

.book-container {
  @include padding('2xl');
  @include padding-top('xl');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.quote {
  @include margin-top('2xl');
  width: 100%;
}
