@import '../../../assets/scss/utils/index.scss';

.service-teaser {
  @include type-style('h4');
  @include padding('lg');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 326px;
  height: 246px;
  position: relative;
  color: $white;
  background: $nachtblau;
}

.icon-container {
  // ...
}
