@import '../../../assets/scss/utils/index.scss';

.inspiration-overlay-service {
  background-color: $nachtblau;
  color: $white;
  width: 100%;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.service-container {
  @include padding('2xl');
  @include padding-top('xl');
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  hyphens: auto;
}

.quote {
  @include padding-left('2xl');
  @include padding-right('2xl');
  @include padding-bottom('2xl');
  position: absolute;
  inset: auto 0 0 0;
  width: 100%;
}

.headline {
  @include type-style('h1');
  margin-left: $speech-bubble-arrow-size;
}

.close-icon {
  @include margin-top('2xl');
  @include margin-right('2xl');
  position: absolute;
  top: 0;
  right: 0;
}
