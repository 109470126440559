@import '../../../assets/scss/utils/index.scss';

.onsite-section {
  @include padding-top('3xl');
  @include padding-right('4xl');
  @include padding-left('4xl');
  flex-direction: column;
  background-color: $white;
  color: $nachtblau;
  flex: 1;
}

.title {
  @include type-style('h2');
  @include margin-bottom('2xl');
  @include margin-left('3xl');
  flex-shrink: 0;
  color: $zueriblau;
}

.scrollbar {
  @include margin-left('4xl', true);
  position: static;
  flex: 1;
}

.ready {
  :global .onsite-group {
    opacity: 1;
    transform: translateY(0);
  }
}
