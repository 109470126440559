@import '../../../assets/scss/utils/index.scss';

.chat-bubble {
  @include type-style('h1-chat');
  position: relative;
  padding: 65px;
  border: none;
  display: inline-block;
  width: 880px;
  color: $white;
  background: $zueriblau;
  white-space: break-spaces;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: -$speech-bubble-arrow-size;
    left: -$speech-bubble-arrow-size;
    width: 0;
    height: 0;
    border-top: $speech-bubble-arrow-size solid transparent;
    border-bottom: $speech-bubble-arrow-size solid transparent;
    border-right: $speech-bubble-arrow-size solid $zueriblau;
    transform: rotate(90deg);
    transform-origin: right;
  }
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(50%);

  @for $i from 1 through 35 {
    &:nth-child(#{$i}) {
      $delay: (25ms * ($i - 1));
      transition: opacity 250ms 0ms + $delay cubic-bezier(0.34, 1.56, 0.64, 1),
        transform 250ms 0ms + $delay cubic-bezier(0.34, 1.56, 0.64, 1);
    }
  }
}

.ready {
  .letter {
    opacity: 1;
    transform: translateY(0);
  }
}
