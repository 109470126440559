@import '../../../assets/scss/utils/index.scss';

.onsite-teaser {
  @include size(442px, auto);
  color: $zueriblau;
}

.image-container {
  position: relative;
  width: 442px;
  height: 466px;
  img {
    @include square(100%);
    object-fit: cover;
  }
}

.content-container {
  @include padding-top('lg');
  @include padding-right('md');
  @include padding-left('md');
}

.title {
  @include type-style('h3');
  @include margin-top('none');
  @include margin-bottom('sm');
}

.text {
  @include type-style('body-xsmall');
  @include margin-bottom('none');
}

.generosity-teaser-wrapper {
  @include margin-top('xl');
  @include margin-bottom('xl');
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(50%);
}
