@import '../../../assets/scss/utils/index.scss';

$border-width: 2px;

.callout-bubble {
  position: relative;
  max-width: 675px;
  padding-left: $speech-bubble-arrow-size;
}

.text-container {
  @include padding('2xl');
  @include type-style('body-large');
  color: $zueriblau;
  border: $border-width solid $zueriblau;
}

.triangle-container {
  position: absolute;
  bottom: 0;
  left: $border-width;
  width: auto;
  height: auto;
  background-color: $white;

  img {
    display: block;
    width: 43px;
    height: 41px;
  }
}
