@import '../../../assets/scss/utils/index.scss';

.button {
  @include type-style('button-large');
  @include padding-top('sm');
  @include padding-right('xl');
  @include padding-bottom('sm');
  @include padding-left('xl');
  border: 2px solid transparent;
  display: inline-block;
  width: auto;
  position: relative;
  cursor: pointer;
}

.is-disabled {
  pointer-events: none;
}

.is-hiding {
  pointer-events: none;
  :global {
    animation: hideAnim 500ms 0ms 1 forwards;
  }
}

.type-primary {
  color: $zueriblau;
  background-color: $white;
  border-color: transparent;

  &.is-disabled {
    color: $grau-dunkel;
    background-color: $zueriblau;
  }

  &:active {
    background-color: $seeblau-hell;
  }
}

.type-secondary {
  color: $white;
  background-color: transparent;
  border-color: $white;

  &.is-disabled {
    color: $grau-dunkel;
    background-color: transparent;
    border-color: $grau-dunkel;
  }

  &:active {
    color: $seeblau-hell;
    border-color: $seeblau-hell;
  }
}
