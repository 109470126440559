@import '../../../assets/scss/utils/index.scss';

.button-select {
  @include type-style('button-large');
  border: 2px solid transparent;
  transition: all 0.15s ease-in;
  border-color: $white;
  background: $nachtblau;
  display: inline-block;
  user-select: none;
  cursor: pointer;
}

.label {
  @include padding-top('sm');
  @include padding-right('xl');
  @include padding-bottom('sm');
  @include padding-left('xl');
  color: $white;
}

.input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.is-selected {
  background: $limmatblau;
  border-color: $limmatblau;

  .label {
    color: $white;
  }
}

.is-disabled {
  border-color: $grau-dunkel;
  background: $nachtblau;
  pointer-events: none;

  .label {
    color: $grau-dunkel;
  }
}
