@import '../../../assets/scss/utils/index.scss';

.restart-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $white;
}

.content-container {
  @include padding('xl');
  @include padding-top('3xl');
  @include padding-bottom('2xl');
  @include margin-bottom('md');
  width: 70%;
  background-color: $white;
  color: $zueriblau;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.title {
  @include margin-bottom('md');
  @include type-style('h2');
}

.text {
  @include type-style('body-small');
}
