@import '../../../assets/scss/utils/index.scss';

.inspiration-section {
  @include padding-top('3xl');
  @include padding-right('4xl');
  @include padding-left('4xl');
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $nachtblau;
  flex: 1;
}

.header {
  @include padding-left('3xl');
}

.title {
  @include type-style('h2');
  @include margin-bottom('md');
  color: $zueriblau;
}

.subline {
  @include type-style('body-small');
  @include margin-bottom('lg');
  color: $zueriblau;
  width: 50vw;
}

.horizontal-scroll-container {
  @include margin-left('4xl', true);
  @include padding-left('4xl');
  position: relative;
  height: auto;
  flex: 1;
}
