@import '../../../assets/scss/utils/index.scss';

.intro {
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
}

.slide {
  will-change: transform, opacity;
  &--enter {
    opacity: 0;
    transform: translate(0, 100%);
  }
  &--exit {
    opacity: 1;
    transform: translate(0, 0);
  }
  &--enter-active,
  &--enter-done {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 500ms 500ms cubic-bezier(0.33, 1, 0.68, 1),
      transform 500ms 500ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  &--exit-active,
  &--exit-done {
    opacity: 0;
    transform: translate(0, -100%);
    transition: opacity 500ms 0ms cubic-bezier(0.32, 0, 0.67, 0),
      transform 500ms 0ms cubic-bezier(0.32, 0, 0.67, 0);
  }
}

.page--even {
  background-color: $nachtblau;
}

.page--odd {
  background-color: $zueriblau;
}

.page-num--0 {
  background-color: $nachtblau;
}

.title {
  @include type-style('h2');
  @include margin-top('2xl');
  @include margin-left('xl');
  position: absolute;
  top: 0;
  left: 0;
  color: $white;
  white-space: pre-wrap;
  opacity: 0;
  transition: opacity 1000ms 0ms;
  &--show {
    opacity: 1;
    transition: opacity 1000ms 1000ms;
  }
}

.start-button {
  @include margin-bottom('3xl');
  @include margin-right('2xl');
  @include z-index('navigation');
  position: absolute;
  inset: auto 0 0 auto;
  // opacity: 0;
  &--show {
    :global {
      animation: showAnim 500ms 3000ms 1 forwards;
    }
  }
  &--hide {
    :global {
      animation: hideAnim 500ms 0ms 1 forwards;
    }
  }
}
